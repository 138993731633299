<template>
  <main-layout>
    <content class="d-block bg-light">
      <header
        class="
          text-center
          d-flex
          align-items-center
          justify-content-center
          flex-column
          asked-question-header
        "
      >
        <h1 style="color: #fff;">Sıkça Sorulan Sorular</h1>
        <p class="mb-0 mx-3">En Çok Merak Ettikleriniz</p>
        <hr />
        <nav class="bg-transparent" aria-label="breadcrumb">
          <ol
            class="breadcrumb bg-transparent text-white d-inline-flex p-0 m-0"
          >
            <li class="breadcrumb-item">
              <a
                @click="$router.push('/')"
                style="cursor: pointer"
                class="icon-home"
              ></a>
            </li>
            <li class="breadcrumb-item" aria-current="page">
              Sıkça Sorulan Sorular
            </li>
          </ol>
        </nav>
      </header>
      <main class="container full py-5">
        <h2 class="mb-4" style="text-align: center">
          En Çok Merak Ettikleriniz
        </h2>
        <div class="accordion mb-5" id="accordionMain">
          <div class="card" v-for="(question, key) in getFaqList" :key="key">
            <div
              @click="isQuestionFn(key)"
              class="card-header"
              :class="{ collapsed: !(key === questionId) }"
              id="acc1head"
              data-toggle="collapse"
              data-target="#acc1"
              :aria-expanded="key === questionId"
              aria-controls="acc1"
            >
              <span>{{ question.Question }}</span>
            </div>
            <div
              v-if="key === questionId"
              id="acc1"
              class="collapse"
              :class="{ show: key === questionId }"
              aria-labelledby="acc1head"
              data-parent="#accordionMain"
              style=""
            >
              <div class="card-body">
                <p>
                  {{ question.Answer }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </content>
  </main-layout>
</template>

<script>
import MainLayout from "../../layouts/MainLayout.vue";
export default {
  name: "AskedQuestion",
  components: {
    MainLayout,
  },
  data() {
    return {
      questionId: null,
    };
  },
  computed: {
    getFaqList() {
      return this.$store.getters["faq/getFaqList"];
    },
  },
  methods: {
    isQuestionFn(item) {
      this.questionId = this.questionId === item ? null : item;
    },
  },
  async created(){
    if(this.getFaqList.length == 0) await this.$store.dispatch("faq/setFaqList");
  }
};
</script>

<style>
</style>